<script lang="ts" setup>
const props = defineProps({
  text: {
    type: String,
    default: "",
  },
});

const copied = ref(false);
const toast = useToast();
const { t } = useI18n();
const copy = () => {
  copied.value = true;
  setTimeout(() => {
    copied.value = false;
  }, 2000);

  toast.add({
    title: t("copy_button.success"),
    icon: "i-ph-check-circle",
    color: "green",
  });
  return navigator.clipboard.writeText(
    props.text.replace(/<\/?[^>]+(>|$)/g, ""),
  );
};
</script>

<template>
  <UTooltip :text="$t('copy_button.tooltip')" :popper="{ placement: 'top' }">
    <UButton
      :icon="copied ? 'i-ph-check-circle' : 'i-ph-copy'"
      variant="soft"
      :disabled="!text ? true : false"
      color="gray"
      size="sm"
      :aria-label="$t('copy_button.tooltip')"
      @click="copy"
    >
      <span class="hidden md:flex">
        {{ $t("copy_button.label") }}
      </span>
    </UButton>
  </UTooltip>
</template>

<style></style>
